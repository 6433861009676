import React from 'react'
import './blog-pages.css'
import blogOneImage from './blog-images/the.jpg'

function blogfive() {
  return (
    <div className='blog-story'>BLOG FIVE</div>
  )
}

export default blogfive