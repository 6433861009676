import React from 'react'
import '../App.css'
import './Gallery.css'
import './general.css'
import { Navbar } from '../components/components'
// import { useRef } from 'react'

function Gallery() {

  return (
       <div className="App">
        <Navbar/>
        <div className="content-loader">
        <div className='galleryPage'>
        <div className='generalCarosel'>
          <div className='overlay'>
          <div className="header">
              <div className="sideMarker">

              </div>
              <h2>Picture Story</h2>
              <p>Explore visual stories showcasing the dreams and aspirations of the girls we support</p>
          </div>
          </div>
        </div>
        {/* <br /><br /> */}
        <div className="album">
            
            <div className="albumOne">
                <h3 id='albumTitle'>MOST RECENT PROJECT</h3>
                <div className="albumSlider album_a" >
                    <div className='may241' ></div>
                    <div className='may242'></div>
                    <div className='may243'></div>
                    <div className='may244'></div>
                    <div className='may245'></div>
                    <div className='may246'></div>
                    <div className='may247'></div>
                    <div className='may248'></div>
                    <div className='may249'></div>
                    <div className='may2410'></div>
                    <div className='may2411'> </div>
                    <div className='may2412'></div>
                    <div className='may2413'></div>
                    <div className='may2414'></div>
                    <div className='may2415'></div>
                </div>
            </div>
            <div className="albumOne">
                <h3 id='albumTitle'>NOT LONG AGO</h3>
                <div className="albumSlider album_a" >
                    <div className='a0001' ></div>
                    <div className='a0002'></div>
                    <div className='a0003'></div>
                    <div className='a0004'></div>
                    <div className='a0005'></div>
                    <div className='a0006'></div>
                    <div className='a0007'></div>
                    <div className='a0008'></div>
                    <div className='a0009'></div>
                    <div className='a0010'></div>
                    <div className='a0011'> </div>
                    <div className='a0012'></div>
                    <div className='a0013'></div>
                    <div className='a0014'></div>
                    <div className='a0015'></div>
                </div>
            </div>
            
            <div className="albumOne">
                <h3 id='albumTitle'>ONE ADVOCATE, DIFFERENT SCHOOLS</h3>
                <div className="albumSlider album_b">
                    <div className='b0001' ></div>
                    <div className='b0002'></div>
                    <div className='b0003'></div>
                    <div className='b0004'></div>
                    <div className='b0005'></div>
                    <div className='b0006'></div>
                    <div className='b0007'></div>
                    <div className='b0008'></div>
                    <div className='b0009'></div>
                    <div className='b0010'></div>
                    <div className='b0011'> </div>
                    <div className='b0012'></div>
                    <div className='b0013'></div>
                    <div className='b0014'></div>
                    <div className='b0015'></div>
                </div>
            </div>
            

            <div className="albumOne">
                <h3 id='albumTitle'>STAFF AND PARENTS</h3>
                <div className="albumSlider album_c">
                    <div className='c0001' ></div>
                    <div className='c0002'></div>
                    <div className='c0003'></div>
                    <div className='c0004'></div>
                    <div className='c0005'></div>
                    <div className='c0006'></div>
                    <div className='c0007'></div>
                    <div className='c0008'></div>
                    <div className='c0009'></div>
                    {/* <div className='c0010'></div>
                    <div className='c0011'> </div>
                    <div className='c0012'></div> */}
                    {/* <div className='c0013'></div>
                    <div className='c0014'></div>
                    <div className='c0015'></div> */}
                </div>
            </div>
            

            <div className="albumOne">
                {/* <h3 id='albumTitle'>PROJECT AT AYEDUASE C&R BASIC SCHOOL</h3> */}
                <div className="albumSlider_d">
                <div className='a0001' ></div>
                    <div className='d0002'></div>
                    <div className='d0003'></div>
                    <div className='d0004'></div>
                    <div className='d0005'></div>
                    <div className='d0006'></div>
                    <div className='d0007'></div>
                    <div className='d0008'></div>
                    <div className='d0009'></div>
                    <div className='d0010'></div>
                    <div className='d0011'> </div>
                    <div className='d0012'></div>
                    <div className='d0013'></div>
                    <div className='d0014'></div>
                    <div className='d0015'></div>
                </div>
            </div>
            
        </div>

    </div>
        </div>
       </div>
  )
}

export default Gallery