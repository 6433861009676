import React, {useState } from 'react'
import './DonateJoin.css'
import axios from 'axios';
import { Navbar } from '../components/components';

// import construction from '../images/construction-icon-38955.png'
function Donate() {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName]= useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [amount, setAmount] = useState('')
  const [paymentMethod,setPaymentMethod]= useState('')
  const [reference, setReference] = useState('')
  const [makeMeAnonymous, setMakeMeAnonymous] = useState(false)
  const [submitResponse, setSubmitResponse] = useState('')
  const [submitStatus, setSubmitStatus] = useState(false)
 
  const resetInputValues=()=>{
    setFirstName('')
    setLastName('')
    setEmail('')
    setPhone('')
    setAmount('')
    setPaymentMethod('')
    setReference('')
    setMakeMeAnonymous(false)
  }

  const donationData = {
    firstName: firstName,
    lastName:lastName,
    email:email,
    phone:phone,
    amount:amount,
    paymentMethod:paymentMethod,
    reference:reference,
    makeMeAnonymous: makeMeAnonymous,

  }

  const handleSubmit =  (e)=>{
   e.preventDefault()
  if(!firstName || !lastName || !email || !phone || !amount || !paymentMethod){
    setSubmitStatus(false)
    setSubmitResponse('Please Fill all required fields (*)')
  }else{
     document.querySelector('#submit').innerHTML ='Submiting....'
    // resetInputValues();
    setSubmitStatus(true)
    postDonate()

  }
 

 
  }

function postDonate(){
      setSubmitResponse('')
      // axios.post('http://localhost:5000/donate', donationData)
      axios.post('https://the-aim-project-foundation-backend.onrender.com/donate', donationData)
      .then((response)=>{
        // console.log(response)
        setSubmitResponse(response.data.message)
        setSubmitStatus(response.data.submitStatus)
        document.querySelector('#submit').innerHTML ='Submit'
        resetInputValues();


      })
      .catch((error)=>{
        console.log(error)
      })
     
      
    }
  


  const handlePaymentMethod = (e) => {
    setPaymentMethod(e.target.value);
  };


const handleCheckBox=()=>{
  setMakeMeAnonymous(!makeMeAnonymous)
}

  return (
    <div className="App">
      <Navbar/>
      <div className="content-loader">
      <div className='donatePage'>
        <h1 id='title'>MAKE A DONATION</h1>
        
      <div className="donateBody">
        <form action="">
            <label>First Name <sup>*</sup>  </label>
            <input type="text" placeholder='William '
              required
              autoComplete='yes'
              value={firstName}
              onChange={(e)=>{setFirstName(e.target.value)}}
            />


            <label>Last Name <sup>*</sup></label>
            <input type="text" placeholder='Doe ' 
             required
             autoComplete='yes'
              value={lastName}
              onChange={(e)=>{setLastName(e.target.value)}}

            />



            <label>Email Address <sup>*</sup></label>
            <input type="email"  placeholder='me@something.com'
             required
             autoComplete='yes'
              value={email}
              onChange={(e)=> {setEmail(e.target.value)}}
            />


            <label>Phone <sup>*</sup></label>
            <input type="text" placeholder='055 342 6684'
              required
              autoComplete='yes'
              value={phone}
              onChange={(e)=>{setPhone(e.target.value)}}

            />


            <label>Amount <sup>*</sup></label>
            <input type="number"  min='0' 
                required
            //  autoComplete='yes'
                placeholder='999'
                value={amount}
                onChange={(e)=>{setAmount(e.target.value)}}

            
            /><br />

            <label>Select Payment Method <sup>*</sup></label>
            {/* <div className="paymentMethod"> */}
            <div className="paymentMethod">
                <input
                  type="radio"
                  name="paymentMethod"
                  value="mobileMoney"
                  checked={paymentMethod === 'mobileMoney'}
                  onChange={handlePaymentMethod}
                />
                <label>Mobile Money</label>
              </div>

              <div className="paymentMethod">
                <input
                  type="radio"
                  name="paymentMethod"
                  value="card"
                  disabled
                  checked={paymentMethod === 'card'}
                  onChange={handlePaymentMethod}
                />
                <label>Card (not available)</label>
              </div>

            <br />





            <label>Reference</label>
            <input type="text" placeholder='eg. 12345 Fundraising contribution'
              value={reference}
              onChange={(e)=>{setReference(e.target.value)}}
            
            />
            <div className="remainAnonymous">
            <input type="checkbox" 
                name='makeMeAnonymous'
                value= {makeMeAnonymous}
                checked={makeMeAnonymous===true}
                onChange={handleCheckBox}
            /> <p>I want to remain anonymous</p>
            </div>
            <button type='submit' onClick={handleSubmit} id='submit'>Submit</button>
            <p style={{textAlign: 'center', marginTop:'1rem', color:submitStatus? 'green':'red' }}>{submitResponse}</p>
            

        </form>
      </div>
      

    
    
    </div>
      </div>
    </div>
  )
}

export default Donate