import React, { useState } from 'react'
import axios from 'axios'
import './Contact.css'
import '../App.css'

import {FaWhatsapp, FaLinkedin, FaTwitter,FaInstagram, FaFacebook } from 'react-icons/fa'
import { Navbar } from '../components/components'

function Contact() {
    const [name, setName]=useState('')
    const [email, setEmail] = useState('')
    const [message,setMessage] =useState('')
    const [messageSubject, setMessageSubject] = useState('')
    const [submitResponse, setsubmitResponse] = useState('')
    const [submitStatus, setSubmitStatus] = useState(false)



    function resetMessageInputs (){
      setName('')
      setEmail('')
      setMessageSubject('')
      setMessage('')
    }

  const messageData = {
    name: name,
    email: email,
    messageSubject: messageSubject,
    message: message
  }

  const handleSubmitMessage=(e)=>{
    e.preventDefault()
    if(!name || !email || !message || !messageSubject) {
      setSubmitStatus(false)
      setsubmitResponse('Please fill all required fields(*)')
    }else{
      document.querySelector('#submit').innerHTML = 'Sending.....'
      // resetMessageInputs()
      setSubmitStatus(true)
      postMessage()
    }


    
  }

  function postMessage(){
    setsubmitResponse('')
    axios.post('https://the-aim-project-foundation-backend.onrender.com/message',messageData)
    .then((response)=>{
      // console.log(response)
      setsubmitResponse(response.data.message)
      setSubmitStatus(response.data.submitStatus)
      resetMessageInputs()
      document.querySelector('#submit').innerHTML = 'Submit'

      
    })
    .catch((error)=>{
      console.log(error)
    })
  }



  return (
    <div className="App">
      <Navbar/>
      <div className="content-loader">
      <div className='contactPage'>
        <div className="contact">
            <div className="contactdetail">
                <h2>Get in Touch</h2>
                <h3>Telephone</h3>
                <p>+233 (0) 597881097</p>
                <p>+233 (0) 553815374</p>
                <br />
                
                <h3>Email</h3>
                <p>tapfound4@gmail.com</p>
                <br />
                {/* <h3>Address</h3>
                <p>13th Avenue</p>
                <p>M'Carthy Hill, Accra</p>
                <br /> */}
                <h3>Our Social Media Presence</h3>
                <div className="socialMediaHandle">
                    <a href="http://wa.me/+233597881097" target='_blank'><FaWhatsapp id='socialMediaIcon'/></a>
                    <a href="https://www.instagram.com/the_aim_pjt/" target='_blank'><FaInstagram id='socialMediaIcon'/></a>
                    <a href=""><FaLinkedin id='socialMediaIcon'/></a>
                    <a href=""><FaTwitter id='socialMediaIcon'/></a>
                    <a href="https://www.facebook.com/the.aim.project.foundation?mibextid=LQQJ4d" target='_blank'><FaFacebook id='socialMediaIcon'/></a>
                </div>
            </div>
            <div className="messageUs">
            <h3>Send us a note</h3>
            {/* <br /> */}
            <form action="">
                <span>Name <sup>*</sup></span><br />
                <input required autoComplete='yes' type="text" placeholder='eg. Kate '
                    value={name}
                    onChange={(e)=>{setName(e.target.value)}}
                    
                /><br />


                <span>Email <sup>*</sup></span><br />
                <input required autoComplete='yes' type="email" placeholder='me@something.com'
                    value={email}
                    onChange={(e)=>{setEmail(e.target.value)}}
                /><br />
                
                
                <span>Subject <sup>*</sup></span><br />
                <input required name="message" id="message" cols="40" rows="3" placeholder='Type your message headeing here......'
                    value={messageSubject}
                    onChange={(e)=>{setMessageSubject(e.target.value)}}

                /><br />
                
                
                <span>Message <sup>*</sup></span><br />
                <textarea required name="message" id="message" cols="40" rows="6" placeholder='Type your message here......'
                    value={message}
                    onChange={(e)=> {setMessage(e.target.value)}}
                
                /><br />
                
                
                <button onClick={handleSubmitMessage} id='submit'>submit</button>
                <p style={{textAlign:'center' , marginTop:'1rem', color: submitStatus? 'green':'red'}}>{submitResponse}</p>
            </form>
            </div>
        </div>
    </div>
      </div>
    </div>
  )
}

export default Contact