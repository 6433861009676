import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import '../App.css';
import {FaWhatsapp, FaLinkedin, FaTwitter,FaInstagram, FaBars, FaTimes, FaFacebook } from 'react-icons/fa'

import logo from '../images/logo.jpg'

const    scrollTop = ()=>{
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}


export function Navbar() {
const [navVisibility, setNavVisibility] = useState(false)
const [isSmallScreen, setIsSmallScreen] = useState(true)





function checkWidth(){
  if(this.window.innerWidth<=650){
    setIsSmallScreen(true)
  }
  else{
    setIsSmallScreen(false)
  }
}

  window.addEventListener('load',checkWidth  )
  window.addEventListener('resize',checkWidth  )

function toggleNavVisibility(){

   setNavVisibility(!navVisibility) 
}



  return (
    <div className="Navbar">
        <NavLink to='/'><div id="logo">
            <img src={logo} alt="" />
        </div></NavLink>
        <div className="nav" style={isSmallScreen ? (navVisibility ? { height: '350px' } : { height: '0rem' }) : { height: 'auto' }}>
        <ul>
          <li onClick={scrollTop}><NavLink to="/" className="link" >Home</NavLink></li>
          <li onClick={scrollTop}><NavLink to="/About" className="link" >About Us</NavLink></li>
          <li onClick={scrollTop}><NavLink to="/Project" className="link" >Project</NavLink></li>
          <li onClick={scrollTop}><NavLink to="/Gallery" className="link" >Gallery</NavLink></li>
          <li onClick={scrollTop}><NavLink to="/Blog" className="link" >Our Blog</NavLink></li>
          <li onClick={scrollTop}><NavLink to="/Contact" className="link" >Contact</NavLink></li>
          <li onClick={scrollTop}>{isSmallScreen ? (navVisibility ? <NavLink to='/Donate'><button  id='donate-button'>Donate</button> </NavLink> : null) : null}</li>
        </ul>
      </div>
      <NavLink to='/Donate'onClick={scrollTop}>
        <div id="donateButton" >
          
          <button>Donate</button>
        </div>
      </NavLink>
      <div className="menuIcon" onClick={toggleNavVisibility}>
      {isSmallScreen ? (navVisibility ?<FaTimes id = 'menuIcon'/>:<FaBars id = 'menuIcon'/>):<FaBars/>}
      </div>
    </div>
  );
}

export function Footer() {
  return (
    <div className="foot">
      
      <div className='socialMediaLinks'>
                    <a href="http://wa.me/+233597881097" target='_blank'><FaWhatsapp id='foot-socialMediaIcon'/></a>
                    <a href="https://www.instagram.com/the_aim_pjt/" target='_blank'><FaInstagram id='foot-socialMediaIcon'/></a>
                    <a href="" target='_blank'><FaLinkedin id='foot-socialMediaIcon'/></a>
                    <a href="" target='_blank'><FaTwitter id='foot-socialMediaIcon'/></a>
                    <a href="https://www.facebook.com/the.aim.project.foundation?mibextid=LQQJ4d" target='_blank'><FaFacebook id='foot-socialMediaIcon'/></a>
      </div>

      <p id='copyright'>Copyright &copy; 2023 TheAimProjectFoundation.</p>
       <p id='designby'>Designed by  <a href="https://wa.me/message/SM2KOT7CET3NG1" id='ifix' target='_blank'>iFiX WEB SERVICES</a></p>
    </div>
  );
}

export default function Components() {
  return (
    <div>
      <Navbar />
      <Footer />
    </div>
  );
}
