import React from 'react'
import '../App.css'
import './About.css'
import './Gallery.css'
import { Navbar } from '../components/components'
function About() {
  return (
    <div className="App">
      <Navbar/>
      <div className="content-loader">
      <div className='aboutPage'>
       <div className='generalCarosel'>
          <div className="overlay">
          <div className="header">
              <div className="sideMarker">

              </div>
              <h2>About Us</h2>
              <p>Meet our team and explore our journey in empowering girls through education</p>
          </div>
          </div>
        </div>
        
        <div className="aboutBody">
          <br />
        <h2 id='important-h2'>The Advisory Board</h2>
            <div className="advisoryBoard">
                <div className="member One">
                      <div className="imgContainer memberOne">

                      </div>
                      <div className='memberData'>
                        <h3 id='name'>Prof. Ibuk Oduro</h3>
                        <h4>Advisor</h4>
                        <h4>KNUST, GHANA</h4>
                      </div>
                  
                  
                </div>
                <div className="member Two">
                      <div className="imgContainer memberTwo">

                      </div>
                      <div className='memberData'>
                        <h3 id='name'>Nicolas Ofoe</h3>
                        <h4>Advisor</h4>
                        <h4>Miami University, Oxford</h4>
                      </div>
                  
                  
                  
                </div>
                
            </div>
            {/* <br /> <br /> */}





            <h2 id='important-h2'>Foundation Executives</h2>

            <div className="executiveBoard">
                <div className="member One">
                      <div className="imgContainer memberThree">

                      </div>
                      <div className='memberData'>
                        <h3 id='name'>Kate Mankattah</h3>
                        <h4>Program's Manager</h4>
                        <h4>KNUST, GHANA</h4>
                      </div>
                </div>

                <div className="member One">
                      <div className="imgContainer memberFour">

                      </div>
                      <div className='memberData'>
                        <h3 id='name'>Ruth Obeneewaa </h3>
                        <h4>General Secreatary</h4>
                      </div>
                </div>
                
                {/* <div className="member One">
                      <div className="imgContainer memberFive">

                      </div>
                      <div className='memberData'>
                        <h3 id='name'>Padmore Joseph Yeboah</h3>
                        <h4>Publicity Head</h4>
                        <h4>KNUST, GHANA</h4>
                      </div>
                </div> */}
                <div className="member One">
                      <div className="imgContainer memberSix">

                      </div>
                      <div className='memberData'>
                        <h3 id='name'>Priscilla Ocansey</h3>
                        <h4>Medical Officer</h4>
                        <h4>KNUST, GHANA</h4>
                      </div>

                </div>
                <div className="member One">
                      <div className="imgContainer memberSeven">

                      </div>
                      <div className='memberData'>
                        <h3 id='name'>Othniel Budu</h3>
                        <h4>Social Media Manager</h4>
                        <h4>China</h4>
                      </div>

                </div>
                <div className="member One">
                      <div className="imgContainer memberEight">

                      </div>
                      <div className='memberData'>
                        <h3 id='name'>Wisdom Gauis</h3>
                        <h4>Associate Director, Grants and Proposal Head</h4>
                        <h4>UMaT, GHANA</h4>
                      </div>
                </div>
                <div className="member One">
                      <div className="imgContainer memberNine">

                      </div>
                      <div className='memberData'>
                        <h3 id='name'>Derrick Adjodeha</h3>
                        <h4>Deputy Associate Director</h4>
                        <h4>KNUST, GHANA</h4>
                      </div>

                </div>

                <div className="member One">
                      <div className="imgContainer memberTen">

                      </div>
                      <div className='memberData'>
                        <h3 id='name'>David Seyra</h3>
                        <h4>Organizing Secretary & Deputy Publicity Head</h4>
                        <h4>KNUST, GHANA</h4>
                      </div>

                </div>
                <div className="member One">
                      <div className="imgContainer memberEleven">

                      </div>
                      <div className='memberData'>
                        <h3 id='name'>Daniel Bodwe </h3>
                        <h4>Program Grapher</h4>
                        <h4>KNUST, GHANA</h4>
                      </div>

                </div>
                {/* <div className="member One">
                      <div className="imgContainer memberTwelve">

                      </div>
                      <div className='memberData'>
                        <h3 id='name'>Baviah B. Abdul Rauf</h3>
                        <h4>Deputy for Grant & Proposal</h4>
                        <h4>KNUST, GHANA</h4>
                      </div>

                </div> */}
            </div>
        </div>





    </div>
      </div>
    </div>



  )
}

export default About