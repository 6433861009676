import React from 'react'
import '../App.css'
import './Project.css'
import { Navbar } from '../components/components'

function Project() {
  return (
    <div className="App">
      <Navbar/>
      <div className="content-loader">
      <div className='projectPage'>
        <div className='generalCarosel'>
          <div className="overlay">
          <div className="header">
              <div className="sideMarker">

              </div>
              <h2>Projects</h2>
              <p>Discover our impactful projects that are changing lives.</p>
          </div>
          </div>
        </div>
        {/* <br /><br />     */}
        <div className="projectBody">
          <h2 id='important-h2'>Upcoming Projects 🥳🥳</h2>
          <div className="projectContainer">
            {/* project template code */}
              <div className="project">
                <div className="imgContainer upcoming ">

                </div>
                <div className="projectDescription">
                    <h3 id='projectTitle'>Project - Save her future</h3>
                    <p>The Aim Project Foundation embarks on its journey again to Akim Oda  Community -St. Francis Senior High School to advocate against teenage Pregnancy, Scholarships in Tertiary, among others. <br /><br />For sponsorship kindly contact; 0597881097. It's your decision and not your Condition that determines your destiny.  <br />    <b style={{fontStyle: 'italic'}}>#TAPF #stopTP #saveherfuture.</b></p>

                </div>

              </div>


              






          </div>
          <h2 id='important-h2'>Most Recent Projects</h2>

          <div className="projectContainer">
            {/* project template code */}
            <div className="project">
                <div className="imgContainer most-recent-one ">

                </div>
                <div className="projectDescription">
                    <h3 id='projectTitle'>December to Remember - Save a Girl Child</h3>
                    <p>The Aim Project Foundation is once again launching a campaign
                       in the Assorko Community (Takoradi) to raise awareness against teenage 
                       pregnancy. <br /> The initiative includes offering skill training for single teenage mothers,
                        providing health talks, distributing sanitary pads, and more!  <br /> For sponsorship or 
                        partnership inquiries, please contact 0597881097. Remember, it's your decisions, not your 
                        conditions, that shape your destiny. <b style={{fontStyle: 'italic'}}>#TAPF #stopTP #saveherfuture.</b></p>

                </div>

              </div>
              <div className="project">
                <div className="imgContainer one">

                </div>
                <div className="projectDescription">
                    <h3 id='projectTitle'>Cluster Of Schools and Community Outreach </h3>
                    <p>In a quaint Ghanaian community, a seminar held at the Topramang Presbyterian Church unfolded with a vital mission – to combat the growing threat of teenage pregnancy. Parents, educators, and community leaders gathered at the local venue with a shared determination to empower the youth and build a safer future.
<br /><br />
{/* A renowned expert took the stage, delivering a compelling address that awakened attendees to the socio-economic repercussions of teenage pregnancies. The day's core lay in interactive workshops, where parents and adolescents participated in sessions on effective communication and sex education. */}

Beyond the seminar hall, volunteers embarked on a community outreach mission, distributing informative materials to homes, schools, and public spaces. Pamphlets created ripples of awareness that extended beyond the seminar walls.
<br /><br />
A diverse panel engaged in a thought-provoking discussion, with solutions emerging, including improved sex education, community support networks, and advocacy for policy changes.

As the day concluded, a collective sense of responsibility hung in the air. The seminar wasn't just an event; it was a catalyst for change. Initiatives were set in motion, including the formation of support networks and the pursuit of targeted educational programs.
<br /><br />
The community pledged to continue the fight, armed with knowledge, collaboration, and a commitment to advocate for policies that protect the dreams and aspirations of their youth. Gratitude filled the air as the community thanked everyone who played a role in this transformative seminar. The journey had just begun, and together, they envisioned a future where teenage pregnancies would be a rarity, and the youth could pursue their dreams unhindered.</p>

                </div>

              </div>


              {/* project template code */}
              <div className="project">
                <div className="imgContainer two">

                </div>
                <div className="projectDescription">
                    <h3 id='projectTitle'>Project Help a Victim</h3>
                    <p>During our outreach in 2022, we encountered an eleven-year-old girl in Topramang, a village in the Eastern Region, who had become a victim of teenage pregnancy. The root cause of her situation was insufficient parental care and control. Due to a divorce, she found herself alone with an unemployed mother responsible for other children. Faced with frustration and inadequate food, her mother, unable to provide proper support, often compelled her to fend for herself. Consequently, she resorted to engaging in relationships with men for financial assistance and sustenance. Coerced into such actions due to poor parental care, she became a tragic victim.
                      <br /><br />
                          This heartbreaking reality emphasizes why TAPF remains committed to educating both students and parents about teenage pregnancy and how collective efforts can address its causes. Witnessing such situations unfold among young girls, who lack knowledge about parenting and childbearing, deeply saddens our hearts. The critical question revolves around their survival. Unfortunately, over 95% of victims discontinue their education after giving birth, leading to the premature end of their dreams, such as aspiring to become a doctor.</p>

                </div>

              </div>

              {/* project template code */}
              <div className="project">
                <div className="imgContainer three">

                </div>
                <div className="projectDescription">
                    <h3 id='projectTitle'> Project Curb Girl Child Unemployment</h3>
                    <p>During our third outreach in the Eastern Region in 2021, we encountered a young girl who had recently completed Junior High School but lacked the support to pursue further education. In our heartfelt conversation with her, she expressed a strong desire to learn the art of dressmaking if given the necessary support. Touched by her passion, the foundation raised funds to fulfill her dream.
                        <br /><br />
                      In June 2022, our team journeyed to Number 4, a small village in the Denkyembour constituency, to sponsor Miss Ophelia's training in dressmaking. We provided the essential tools, materials, and provisions to facilitate her learning journey without hindrances. Miss Ophelia has now successfully enrolled in the apprenticeship program. The foundation extends its best wishes to her as she embarks on this new chapter in her life.</p>

                </div>

              </div>






          </div>

            
        </div>
        </div>
      </div>
    </div>
  )
}

export default Project