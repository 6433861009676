import React from 'react'
import '../App.css'
import './Error404.css'
import { Navbar } from '../components/components'
function PageNotFound() {
  return (
   <div className="App">
    <Navbar/>
    <div className="content-loader">
    <div className='ErrorPage'>
        <h2> OOPS! PAGE NOT FOUND. </h2>
        <p>The page you are searching for <b>doesn't exist</b> or may still be under construction.</p>
    </div>
    </div>
   </div>
  )
}

export default PageNotFound