import React from 'react'
import '../App.css'
import { NavLink } from 'react-router-dom';
import { FaFemale,FaSchool, FaUsers,  FaBullseye, FaEye, FaHeart } from 'react-icons/fa'
import { Navbar } from '../components/components';

function Home() {
  window.addEventListener('resize', () => {
    const body = document.querySelector('body');
    body.style.height = window.innerHeight + 'px';
  });
  return (
    <div className="App">
      <Navbar/>
      <div className="content-loader">
      <div className='Home'>
       <div className="carosel">
            <div className="overlay"> </div>
            <div className="caroselText">
                {/* <div className="floatingborder"></div> */}
                <h2>The AIM Project</h2>
                <p> Advocate against teenage pregnancy and providing skill training for single teenage mothers</p>
                <br />
                <NavLink to='/Project'><button id='joinButton'>See our Works</button></NavLink>
            </div>
            {/* </div> */}
        </div>
        <div className="homeBody">
        <h2>OUR CAUSE</h2>
            <div className="cause">
                <div className='vision'>
                  <FaEye id='causeIcon'/>
                  <h3>Our Vision</h3>
                  <p>Empowering single and young mothers for a brighter future.</p>
                </div>

                <div className='mission'>
                  <FaBullseye id='causeIcon'/>
                  <h3>Our Mission</h3>
                  <p>Advocating against teenage pregnancy and providing skill training to young single mothers</p>
                </div>

                <div className='value'>
                  <FaHeart id='causeIcon'/>
                  <h3>Our Values</h3>
                  <p>Empowerment, Advocacy, Education, Giving Back to Society, Equality, Resilience</p>
                </div>
            </div>



            <br /><br /><br /><br /><br />
              <div className="impact">
                <div className="overlay">
                < h2>OUR IMPACT IN NUMBERS</h2>
                             
                  <div className="impactBody">

                    <div>
                      <div id='impactIcon'>
                        <FaFemale/>
                      </div>
                      <h3>1000+ Girls</h3>
                    </div>
                    <div>
                      <div id='impactIcon'>
                        <FaSchool/>
                      </div>
                      <h3>20+ Schools </h3>
                    </div>
                    <div>
                      <div id='impactIcon'>
                        <FaUsers/>
                      </div>
                      <h3>7+ Communities</h3>
                    </div>
                    
                                
             
                  </div>
                </div>

              </div>









            <br /><br /><br /><br /><br />
            <h2 id='motivation-title'>OUR MOTIVATION</h2>
            <div className="OurMotivation">
              
              <div className='motivation1'>
                <div className="imgContainer image1">

                </div>
                <p>"Empower a girl, and you empower a generation. Education is the key to unlocking her potential and breaking the cycle of teenage pregnancy. Let's stand together and give every girl the tools to create her own future."</p>
              </div>

              <div className='motivation2' >
                <div className="imgContainer image2">

                </div>
                <p>"Every girl has the right to dream, learn, and grow. Through education, we can rewrite the story of countless lives and inspire them to reach for the stars. Say no to teenage pregnancy, say yes to a brighter future."</p>
              </div>

              <div className='motivation3'>
                <div className="imgContainer image3">

                </div>
                <p>"The strength of a girl's education can change the world. Let's champion their dreams, provide opportunities, and fight against teenage pregnancy. Together, we can create a world where every girl's potential knows no bounds."</p>
              </div>
              
              
            </div>
            
        </div>
    </div>
      </div>
    </div>
  )
}

export default Home