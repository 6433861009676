import React from 'react'
import './mediaUploads.css'

function MediaUploads() {
  return (
    <div className='uploadPage'>
        <h2>ADMIN FOR AIM FOUNDATION PROJECT</h2>
    </div>
  )
}

export default MediaUploads