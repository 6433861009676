import React from 'react'
import '../App.css'
import './Blog.css'
import './Project.css'
import { Navbar } from '../components/components'


function Blog() {
  return (
    <div className="App">
      <Navbar/>
      <div className="content-loader">
      <div className='blogPage'>
        <div className='generalCarosel'>
            <div className="overlay">
            <div className="header">
              <div className="sideMarker">

              </div>
              <h2>Our Blog</h2>
              <p>Meet our team and explore our journey in empowering girls through education</p>
          </div>
            </div>
        </div>
        
        <div className="blogBody">
        <h2 id='blog-header'>NO STORIES AVAILABLE FOR NOW.</h2>

            <div className='blogContainer'>

                  {/* <NavLink to='/Blogone'className='blog'>
                      <div className="filter"></div>
                      <div className="imageContainer one">
                        <img src="" alt="" />
                      </div>
                      <p id='blogTitle'> <b>THE BIG TURN-AROUND:</b> <br /> Life changing Story of a young Female</p>

                  </NavLink>

                  <NavLink to='/Blogtwo' className='blog'>
                      <div className="filter"></div>
                      <div className="imageContainer one">
                        <img src="" alt="" />
                      </div>
                      <p id='blogTitle'> <b>THE BIG TURN-AROUND:</b> <br /> Life changing Story of a young Female</p>

                  </NavLink>

                  <NavLink to='/Blogthree' className='blog'>
                      <div className="filter"></div>
                      <div className="imageContainer one">
                        <img src="" alt="" />
                      </div>
                      <p id='blogTitle'> <b>THE BIG TURN-AROUND:</b> <br /> Life changing Story of a young Female</p>

                  </NavLink>

                  <NavLink to='/Blogfour' className='blog'>
                      <div className="filter"></div>
                      <div className="imageContainer one">
                        <img src="" alt="" />
                      </div>
                      <p id='blogTitle'> <b>THE BIG TURN-AROUND:</b> <br /> Life changing Story of a young Female</p>

                  </NavLink>

                   */}
                

            </div>

        </div>
        </div>
      </div>
    </div>

        
  )
}

export default Blog