import React from 'react'
import './DonateJoin.css'
import construction from '../images/construction-icon-38955.png'

function Join() {
  return (
    <div className='construction'>
        <h1 id='title'>JOIN US PAGE</h1>
        <h1>THIS PAGE IS STILL UNDER CONSTRUCTION</h1>
        <p>Navigate Carefully. Wet Codes Ahead hihihi</p>
        <img src={construction} alt="" />

    </div>
  )
}

export default Join