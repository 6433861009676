// import logo from './logo.svg';
import './App.css';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';

import Home from './pages/Home'
import About from './pages/About'
import Blog from './pages/Blog'
import Contact from './pages/Contact'
import Gallery from './pages/Gallery'
import Project from './pages/Project'
import PageNotFound from './pages/Error404'
import Join from './pages/Join';
import Donate from './pages/Donate';
import MediaUploads from './pages/mediaUploads';
import Blogone from './pages/Blog-pages/blogone';
import Blogthree from './pages/Blog-pages/blogthree';
import Blogtwo from './pages/Blog-pages/blogtwo';
import Blogfour from './pages/Blog-pages/blogfour';
import Blogfive from './pages/Blog-pages/blogfive';





import { Navbar,Footer } from './components/components';

 

function App() {
  return (
      <Router basename='/'>
        <div className="App">
          {/* <Navbar/> */}
            <div>
              <Routes>
                  <Route index element = {<Home/>}/>
                  <Route path='/About' element={<About/>}/>
                  <Route path='/Blog' element={<Blog/>}/>
                  <Route path='/Contact' element={<Contact/>}/>
                  <Route path='/Gallery' element={<Gallery/>}/>
                  <Route path='/Project' element={<Project/>}/>
                  <Route path='/Join' element={<Join/>}/>
                  <Route path='/Donate' element={<Donate/>}/>
                  <Route path='/MediaUploads' element={<MediaUploads/>}/>
                  <Route path='/Blogone' element={<Blogone/>}/>
                  <Route path='/Blogtwo' element={<Blogtwo/>}/>
                  <Route path='/Blogthree' element={<Blogthree/>}/>
                  <Route path='/Blogfour' element={<Blogfour/>}/>
                  <Route path='/Blogfive' element={<Blogfive/>}/>
                  <Route path='*' element={<PageNotFound/>}/>
              </Routes>
            </div>
            <Footer/>
        </div>
      </Router>


  );
}

export default App;
